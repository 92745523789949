import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import turmeric from "images/products/turmeric.jpg";
import blackTurmeric from "images/products/black-turmeric.png";
import basilSeeds from "images/products/basil-seeds.png";
import papaya from "images/products/papaya.jpg";
import moringa from "images/products/moringa.jpg";
import jamun from "images/products/jamun.jpg";
import hibiscus from "images/products/hibiscus.jpg";
import amla from "images/products/amla.jpg";
import aloevera from "images/products/aloevera.jpg";
import neem from "images/products/neem.jpg";
import rosePetal from "images/products/rosePetal.jpeg";
import jasmine from "images/products/jasmine.jpeg";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import MainFeature from "components/features/TwoColWithSteps.js";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/3 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-contain relative rounded-t bg-no-repeat`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`; const Description = tw.span`inline-block mt-8`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-2 h-full`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
export default ({
  heading = "Checkout the Menu",
  tabs = [
    {
      imageSrc: turmeric,
      title: "Turmeric",
    },
    {
      imageSrc: blackTurmeric,
      title: "Black Turmeric",
    },
    {
      imageSrc: basilSeeds,
      title: "Basil Seeds",
    },
    {
      imageSrc: papaya,
      title: "Papaya",
    },
    {
      imageSrc: moringa,
      title: "Moringa",
    },
    {
      imageSrc: jamun,
      title: "Indian Blackberry",
    },
    {
      imageSrc: hibiscus,
      title: "Hibiscus",
    },
    {
      imageSrc: amla,
      title: "Amla",
    },
    {
      imageSrc: aloevera,
      title: "Aloevera",
    },
    {
      imageSrc: neem,
      title: "Neem",
    },
    {
      imageSrc: rosePetal,
      title: "Rose Petal",
    },
    {
      imageSrc: jasmine,
      title: "Jasmin",
    },
  ]
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  // const tabsKeys = Object.keys(tabs);
  // const [activeTab, setActiveTab] = useState(tabsKeys[0]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  // const toggleModal = () => setModalIsOpen(!modalIsOpen);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <Container>
      <ContentWithPaddingXl>
        <Header>{heading}</Header>
        <TabContent
          variants={{
            current: {
              opacity: 1,
              scale: 1,
              display: "flex",
            },
            hidden: {
              opacity: 0,
              scale: 0.8,
              display: "none",
            }
          }}
          transition={{ duration: 0.4 }}
        >
          {tabs.map((card, index) => (
            <CardContainer key={index}>
              <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                <CardImageContainer imageSrc={card.imageSrc}>
                  {/* <CardRatingContainer>
                    <CardRating>
                      <StarIcon />
                      {card.rating}
                    </CardRating>
                    <CardReview>({card.reviews})</CardReview>
                  </CardRatingContainer> */}
                  {/* <CardHoverOverlay
                    variants={{
                      hover: {
                        opacity: 1,
                        height: "auto"
                      },
                      rest: {
                        opacity: 0,
                        height: 0
                      }
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    <CardButton onClick={() => { setSelectedItem(card); toggleModal(); }}>Show Details</CardButton>
                  </CardHoverOverlay> */}
                </CardImageContainer>
                <CardText>
                  <CardTitle>{card.title}</CardTitle>
                  {/* <CardContent>{card.content}</CardContent> */}
                  {/* <CardPrice>{card.price}</CardPrice> */}
                </CardText>
              </Card>
            </CardContainer>
          ))}
        </TabContent>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={true}
      >
        <CloseModalButton onClick={toggleModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <div className="content overflow-auto">
          <MainFeature
            heading={
              <>
                {selectedItem?.title}
              </>
            }
            description={
              <Description>
                Leading Seeds, Herbs, Spices And Nutraceutical Products Exporter In India
                <br /><br />Established Since 2018, SAIPANKH TRADEX PVT LTD  is a pioneering Indian exporter of premium-quality seeds, herbs, spices, and nutraceutical products.
                <br /><br />Co-founded by PINKAL PATEL / VIRAJ PATEL , the company’s commitment to quality has enabled it to become a recognized name in the industry. With a strong focus on delivering products that meet global standards, SAIPANKH has emerged as a trusted partner for clients around the world. Our commitment is to maintain the highest quality standards at every stage of the manufacturing process.
                <br /><br />By harnessing the latest technology and employing an experienced team of professionals, the company ensures the purity, safety, and efficacy of every product. Our dedicated team is committed to satisfying the needs of clients worldwide, whether it’s by sourcing the highest quality ingredients, ensuring timely delivery, or offering unparalleled customer service.
                <br /><br />With over a decade of experience, we have satisfied more than 6749 clients across 50+ countries. Our extensive range of 300+ products caters to diverse requirements and is manufactured with a mission to achieve a chemical-free world.
                <br /><br />In addition to our Indian products, we are also dedicated to providing nutraceutical solutions through our brand STAYALIVE. We understand the need for white labeling and are committed to supplying products tailored to our clients’ needs. We handle all aspects of shipping and handling to ensure that our clients receive their products in a timely and hassle-free manner.
                <br /><br />We take pride in sourcing our products from various regions of India, collaborating with trusted farmers and suppliers to maintain the highest quality standards. We are committed to ensuring customer satisfaction by meeting their unique requirements and establishing long-term partnerships.
              </Description>
            }
            buttonRounded={false}
            textOnLeft={false}
            primaryButtonText="Latest Offers"
            imageDecoratorBlob={true}
            imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
            steps={selectedItem?.benefits || []}
          />
          {/* <ProductDetails /> */}
          {/* <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" /> */}
        </div>
      </StyledModal>
    </Container>
  );
};

