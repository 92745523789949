import TabGrid from "components/cards/TabCardGrid.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Features from "components/features/RowImages.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import tw from "twin.macro";

import celebrationIconImageSrc from "images/celebration-icon.svg";
import chefIconImageSrc from "images/chef-icon.svg";
import image1 from "images/img1.png";
import shopIconImageSrc from "images/shop-icon.svg";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      {/* <Header /> */}
      <Hero
        heading={<>Quality Exporter From Years</>}
        description="WE ARE THE BEST IN THE BUSINESS WHEN IT COMES TO SEEDS, HERBS, ORGANIC TEA AND SPICES.
        We are committed to providing you with the highest quality products and exceptional service, no matter where you are in the world.”
        "
        imageSrc={image1}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText=""
        watchVideoButtonText=""
      />
      <MainFeature
        heading={
          <>
            About Us
          </>
        }
        description={
          <Description>
            Leading Seeds, Herbs, Spices And Nutraceutical Products Exporter In India
            <br /><br />Established Since 2018, SAIPANKH TRADEX PVT LTD  is a pioneering Indian exporter of premium-quality seeds, herbs, spices, and nutraceutical products.
            <br /><br />Co-founded by PINKAL PATEL / VIRAJ PATEL , the company’s commitment to quality has enabled it to become a recognized name in the industry. With a strong focus on delivering products that meet global standards, SAIPANKH has emerged as a trusted partner for clients around the world. Our commitment is to maintain the highest quality standards at every stage of the manufacturing process.
            <br /><br />By harnessing the latest technology and employing an experienced team of professionals, the company ensures the purity, safety, and efficacy of every product. Our dedicated team is committed to satisfying the needs of clients worldwide, whether it’s by sourcing the highest quality ingredients, ensuring timely delivery, or offering unparalleled customer service.
            <br /><br />With over a decade of experience, we have satisfied more than 6749 clients across 50+ countries. Our extensive range of 300+ products caters to diverse requirements and is manufactured with a mission to achieve a chemical-free world.
            <br /><br />In addition to our Indian products, we are also dedicated to providing nutraceutical solutions through our brand STAYALIVE. We understand the need for white labeling and are committed to supplying products tailored to our clients’ needs. We handle all aspects of shipping and handling to ensure that our clients receive their products in a timely and hassle-free manner.
            <br /><br />We take pride in sourcing our products from various regions of India, collaborating with trusted farmers and suppliers to maintain the highest quality standards. We are committed to ensuring customer satisfaction by meeting their unique requirements and establishing long-term partnerships.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={image1}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Checkout our <HighlightedText>products.</HighlightedText>
          </>
        }
      />
      <Features
        heading={
          <>
            Our <HighlightedText>Certificates.</HighlightedText>
          </>
        }
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-40! h-40!`}
      />
      {/* <Features
        heading={
          <>
            Amazing <HighlightedText>Services.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "230+ Locations",
            description: "Lorem ipsum donor amet siti ceali placeholder text",
            url: "https://google.com"
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Professional Chefs",
            description: "Lorem ipsum donor amet siti ceali placeholder text",
            url: "https://timerse.com"
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Birthday Catering",
            description: "Lorem ipsum donor amet siti ceali placeholder text",
            url: "https://reddit.com"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={<>Why <HighlightedText>Choose Us ?</HighlightedText></>}
        statistics={[
          {
            key: "Orders",
            value: "94000+",
          },
          {
            key: "Customers",
            value: "11000+"
          },
          {
            key: "Chefs",
            value: "1500+"
          }
        ]}
        primaryButtonText="Order Now"
        primaryButtonUrl="https://order.now.com"
        imageInsideDiv={false}
        imageSrc="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      />
      <DownloadApp
        text={<>People around you are ordering delicious meals using the <HighlightedTextInverse>Treact App.</HighlightedTextInverse></>}
      /> */}
      {/* <Blog /> */}
      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  );
}
